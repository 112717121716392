<template>
<div class="apCodeUpload-page-content winner-page__hero">
   <div class="main-block position-relative">
      <div class="container-xl py-lg-5">
        <div class="row" :class="winnerType == 'heti' ? 'winner-bg--heti':''">
            <div class="col-12 pt-lg-5 custom-padding">
                <div class="winner-bg">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="text-center winner-page--text d-flex justify-content-center align-items-center flex-column h-100">
                                <h1 class="text-uppercase fw-700 mb-2" style="color:#FEEF0A;">Gratulálunk!</h1>  
                                <p class="text-white fs-22 mb-2 font-primary">Érvényes pályázat esetén nyereményed lehet</p>            
                                <p class="text-white fs-22 text-center font-primary">
                                    <span class="winner-page__hero__winning text-uppercase" v-if="winnerType == 'napi'">1 db 10.000 Ft értékű<br>Auchan<br>ajándékkártya</span>
                                 <!--   <span class="winner-page__hero__winning text-uppercase" v-if="winnerType == 'heti'">1 db 100.000 FT pénznyeremény</span><br>
                                    <span v-if="winnerType == 'heti'">bankszámlára utalva</span>
                                    <span class="winner-page__hero__winning " v-if="winnerType == 'fodij'">1 db 500.000 Ft értékű IBUSZ vásárlási utalvány</span>
                               --> </p>
                            </div>
                        </div>   
                        <div class="col-12 col-lg-6">
                            <img v-if="winnerType == 'napi'" src="@/assets/imgs/winner-hero-napi.png" alt="napi nyememény" class="img-fluid"/>
                        <!--    <img v-if="winnerType == 'heti'" src="@/assets/imgs/winner-hero-heti.png" alt="heti nyeremény" class="img-fluid"/>
                            <img v-if="winnerType == 'fodij'" src="@/assets/imgs/winner-hero-fodij.png" alt="heti nyeremény" class="img-fluid"/>
                        --></div>
                    </div>
                </div>
            </div>
        </div>   
    </div>    
  </div>
   <!-- <div class="bg-primary">
        <div class="col-12 col-lg-8 mx-auto text-center fs-14 fs-md-18 text-white py-5 d-flex flex-wrap justify-content-center align-items-center ">                                
            <p class="mb-0">A vásárlást igazoló eredeti bizonylatot sértetlen, ép állapotban <b>2024. szeptember 28-ig</b> meg kell őrizni. <br>A képek csak illusztrációk.</p>
        </div>    
    </div>
  <Ribbon/>-->
</div>
</template>

<script>
//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    props:['winnerType']
}
</script>