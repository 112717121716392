<template>
  <div id="winner-page" class="winner-page">
    <Header />
    <div class="winner-page__content">      
      <CodeFillingWinnerForm/>
    </div>
    <Footer />  
  </div>
</template>

<script>
import CodeFillingWinnerForm from '@/components/CodeFillingWinner-form.vue'
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"

export default {
  components: {
    CodeFillingWinnerForm   ,
    Header,
     Footer
  },
  created(){
        if (document.querySelectorAll('meta[content="noindex, nofollow"]').length === 0) {
            var m = document.createElement('meta'); 
            m.name = 'robots'; 
            m.content = 'noindex, nofollow'; 
            document.head.appendChild(m);
        }
    },
}
</script>
